import parser from 'html-react-parser';

interface Props {
  title: string;
  description: string;
}

const ExplainSection: React.FC<Props> = ({ title, description }) => {
  return (
    <section className="text-gray-700 body-font" id="explain">
      <div className="container px-5 pb-8 mx-auto">
        <div className="mb-16 text-center">
          <h1 className="mx-auto text-xl font-bold text-san-juan-blue-500 sm:text-2xl lg:text-3xl title-font md:max-w-2xl">
            {parser(title)}
          </h1>
          <div className="flex justify-center my-8">
            <div className="inline-flex w-16 h-1 bg-summer-green-500 rounded-full"></div>
          </div>
          <div className="mx-auto text-lg text-center sm:text-left leading-relaxed md:max-w-2xl">
            {parser(description)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExplainSection;
