import { Feature } from 'interfaces/home';

interface Props {
  features: Feature[];
}

const Features3: React.FC<Props> = ({ features }) => {
  return (
    <div className="flex flex-wrap -m-4">
      {features?.map((feature: Feature, i: number) => {
        return (
          <div
            className={`flex flex-col items-center pb-10 mx-auto mb-10 border-b border-gray-200 sm:flex-row ${
              i % 2 === 0 ? 'sm:flex-row-reverse' : ''
            }`}
            key={`feature-${i}`}
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-4/5 sm:w-1/3 border border-gray-300 sm:mx-5 rounded-lg">
              <img
                src={feature.image}
                alt={feature.name}
                className="rounded-lg"
              />
            </div>
            <div className="flex-grow mt-6 text-center sm:text-left sm:mt-0 sm:mx-5">
              <h2 className="mb-2 text-lg font-medium text-san-juan-blue-500 title-font">
                {feature.name}
              </h2>
              <p className="text-base leading-relaxed">{feature.description}</p>
              <a
                className="inline-flex items-center mt-3 text-summer-green-700"
                href={feature.learn_more}
              >
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Features3;
