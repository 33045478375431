import { Step } from 'interfaces/home';
import Steps1 from './Steps1';
import Steps2 from './Steps2';
import Steps3 from './Steps3';

interface Props {
  version: number;
  image: string;
  steps: Step[];
}

const StepsSection: React.FC<Props> = ({ version, steps, image }) => {
  // List of different component versions. You can easily switch between versions from the CMS.
  const components = {
    1: Steps1,
    2: Steps2,
    3: Steps3,
  };

  // Use the version prop to determine which component to render. Fallback to 1.
  const StepsComponent = components[version] || components[1];

  // Default return version 1, with hero image on the right
  return (
    <>
      <div className="text-center">
        <h1 className="my-4 text-2xl font-bold text-san-juan-blue-500 sm:text-3xl lg:text-4xl title-font">
          How does it work?
        </h1>
        <div className="flex justify-center mt-6 mb-12">
          <div className="inline-flex w-16 h-1 bg-summer-green-500 rounded-full"></div>
        </div>
      </div>
      <StepsComponent steps={steps} image={image} />
      <div className="flex mt-16">
        <a
          href="https://docs.latencylingo.com/docs/intro"
          target="_blank"
          rel="noreferrer"
          className="mx-auto"
        >
          <button className="px-8 py-4 text-lg font-medium text-summer-green-700 bg-summer-green-100 border border-summer-green-200 hover:bg-summer-green-200 rounded focus:outline-none">
            Read more in our documentation
          </button>
        </a>
      </div>
    </>
  );
};

export default StepsSection;
