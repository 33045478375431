import { NextPage, GetStaticProps } from 'next';

import SEO from 'components/home/SEO';
import HeroSection from 'components/home/HeroSection';
import Layout from 'components/home/Layout';
import FeatureSection from 'components/home/FeatureSection';
import { HomeAttributes } from 'interfaces/home';
import CtaSection from 'components/home/CtaSection';
import PricingSection from 'components/home/PricingSection';
import StepsSection from 'components/home/StepsSection';
import BlogSection from 'components/home/BlogSection';
import ExplainSection from 'components/home/ExplainSection';
import LogoSection from 'components/home/LogoSection';

interface Props {
  content: { attributes: HomeAttributes };
}

const HomePage: NextPage<Props> = ({ content }) => {
  const { attributes } = content;

  return (
    <>
      <SEO />
      <Layout>
        <div>
          <HeroSection
            version={attributes.hero_version}
            title={attributes.hero_title}
            description={attributes.hero_description}
            image={attributes.hero_image}
          />
          <FeatureSection
            version={attributes.feature_version}
            title={attributes.feature_title}
            description={attributes.feature_description}
            features={attributes.features}
          />
          <StepsSection
            version={attributes.steps_version}
            steps={attributes.steps}
            image={attributes.steps_image}
          />
          <LogoSection />
          <PricingSection
            title={attributes.pricing_title}
            description={attributes.pricing_description}
            plans={attributes.plans}
          />
          <ExplainSection
            title={attributes.explain_title}
            description={attributes.explain_description}
          />
          <CtaSection />
          {/* <DemoSection /> */}
          <BlogSection
            version={attributes.blog_version}
            title={attributes.blog_title}
            description={attributes.blog_description}
            slugs={attributes.posts}
          />
        </div>
      </Layout>
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const content = await import(`../content/pages/${'home'}.md`);

  return { props: { content: content.default } };
};

export default HomePage;
