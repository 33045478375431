import { Feature } from 'interfaces/home';
import { VscDebugDisconnect, VscRocket } from 'react-icons/vsc';
import { AiOutlineBarChart } from 'react-icons/ai';

interface Props {
  features: Feature[];
}

const getIcon = (i: number, size = 5) => {
  switch (i) {
    case 0:
      return <VscRocket className={`h-${size} w-${size}`} />;
    case 1:
      return <AiOutlineBarChart className={`h-${size} w-${size}`} />;
    case 2:
      return <VscDebugDisconnect className={`h-${size} w-${size}`} />;
  }
};

const Features2: React.FC<Props> = ({ features }) => {
  return (
    <div className="flex flex-wrap -m-4">
      {features?.map((feature: Feature, i: number) => {
        return (
          <div className="p-4 md:w-1/3" key={`feature-${i}`}>
            <div className="flex flex-col h-full p-8 bg-gray-100 rounded-lg">
              <div className="flex items-center mb-3">
                <div className="inline-flex items-center justify-center flex-shrink-0 w-14 h-14 mr-3 text-san-juan-blue-500 bg-summer-green-400 rounded-full">
                  {getIcon(i, 8)}
                </div>
                <h2 className="text-lg font-medium text-san-juan-blue-600 title-font">
                  {feature.name}
                </h2>
              </div>
              <div className="flex-grow">
                <p className="text-base leading-relaxed">
                  {feature.description}
                </p>
                {feature.learn_more && (
                  <a
                    className="inline-flex items-center mt-3 text-summer-green-700"
                    href={feature.learn_more}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Features2;
