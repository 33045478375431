interface LogoProps {
  src: string;
}

const Logo: React.FC<LogoProps> = ({ src }) => {
  return (
    <div className="mt-2 w-44 mx-auto">
      <img src={src} alt={src} />
    </div>
  );
};

const LogoSection: React.FC = () => {
  return (
    <section className="text-gray-700 body-font" id="logos">
      <div className="container pt-20 px-5 mx-auto">
        <div className="mb-16 text-center">
          <h1 className="mx-auto text-xl font-bold text-san-juan-blue-500 sm:text-2xl lg:text-3xl title-font md:max-w-2xl">
            Join the best performance testers
          </h1>
          <div className="flex justify-center my-8">
            <div className="inline-flex w-16 h-1 bg-summer-green-500 rounded-full"></div>
          </div>
        </div>
        <div className="sm:flex justify-between items-center">
          <Logo src="img/dxc_logo.png" />
          <Logo src="img/cognizant_logo.png" />
          <Logo src="img/colan_logo.png" />
        </div>
      </div>
    </section>
  );
};

export default LogoSection;
