import { Post } from 'interfaces/post';
import BlogCard from '../BlogCard';

interface Props {
  posts: { attributes: Post }[];
}

const Posts1: React.FC<Props> = ({ posts }) => {
  return (
    <div className="grid max-w-lg gap-5 mx-auto mt-12 md:grid-cols-2 lg:grid-cols-3 md:max-w-none">
      {posts?.map((post, i) => {
        return <BlogCard version={1} withImage={false} post={post} key={i} />;
      })}
    </div>
  );
};

export default Posts1;
