import { useEffect, useState } from 'react';
import TextTransition from 'react-text-transition';

interface Props {
  title: string;
  description: string;
  image: string;
}

const TEXTS = ['fast', 'error-free', 'resilient', 'secure'];

const Hero1: React.FC<Props> = (): JSX.Element => {
  const [textIndex, setTextIndex] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setTextIndex((textIndex) =>
          textIndex < TEXTS.length - 1 ? textIndex + 1 : 0
        ),
      3000
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <section className="text-gray-700 body-font bg-gray-100">
      <div className="container flex flex-col items-center px-5 pt-24 pb-5 mx-auto md:flex-row">
        <div className="flex flex-col items-center mb-16 text-center sm:text-left w-full xl:w-2/3 mx-auto">
          <h1 className="mb-4 text-3xl font-bold text-san-juan-blue-500 title-font sm:text-5xl">
            Ship fast web apps
            <br />
            that&nbsp;
            <div className="inline-block text-summer-green-700">
              stay&nbsp;
              <div className="inline-block w-36 h-9 sm:w-60 sm:h-12 overflow-y-hidden align-bottom text-left">
                <TextTransition className="!inline-block">
                  {TEXTS[textIndex]}
                </TextTransition>
              </div>
            </div>
          </h1>
          <p className="mb-8 sm:text-xl leading-relaxed max-w-xl">
            Use Latency Lingo to measure performance changes in your test
            environment and catch decline before your users do.
          </p>
          <div className="flex justify-center">
            <a href="/signup">
              <button className="px-8 py-4 text-lg font-medium text-san-juan-blue-500 bg-summer-green-400 shadow border border-summer-green-500 rounded focus:outline-none hover:bg-summer-green-500">
                Try FREE now
              </button>
            </a>
            <a
              href="https://docs.latencylingo.com/docs/intro"
              target="_blank"
              rel="noreferrer"
            >
              <button className="ml-4 px-8 py-4 text-lg font-medium text-summer-green-700 bg-summer-green-100 shadow border border-summer-green-300 rounded focus:outline-none hover:bg-summer-green-200">
                Learn more
              </button>
            </a>
          </div>
        </div>
        {/* <div className="hidden w-5/6 lg:max-w-lg lg:w-full md:w-1/2">
          <img
            className="object-cover object-center rounded-lg"
            alt="hero"
            src={image}
            loading="lazy"
          />
        </div> */}
      </div>
    </section>
  );
};

export default Hero1;
