import { Feature } from 'interfaces/home';
import Features1 from './Features1';
import Features2 from './Features2';
import Features3 from './Features3';
import parser from 'html-react-parser';

interface Props {
  title: string;
  description: string;
  features: Feature[];
  version: number;
}

const FeatureSection: React.FC<Props> = ({
  title,
  description,
  features,
  version,
}) => {
  // List of different component versions. You can easily switch between versions from the CMS.
  const components = {
    1: Features1,
    2: Features2,
    3: Features3,
  };

  // Use the version prop to determine which component to render. Fallback to 1.
  const FeatureList = components[version] || components[1];

  return (
    <section className="text-gray-700 body-font" id="features">
      <div className="container px-5 py-16 mx-auto">
        <div className="mb-16 text-center">
          <h1 className="mx-auto mb-8 text-xl font-bold text-san-juan-blue-500 sm:text-2xl lg:text-3xl title-font md:max-w-2xl">
            {parser(title)}
          </h1>
          <div className="mx-auto text-lg text-center sm:text-left leading-relaxed md:max-w-2xl">
            {parser(description)}
          </div>
          <div className="flex justify-center mt-8">
            <div className="inline-flex w-16 h-1 bg-summer-green-500 rounded-full"></div>
          </div>
        </div>
        <FeatureList features={features} />
        <div className="flex justify-center mt-16">
          <a href="/signup">
            <button className="px-8 py-4 text-lg font-medium text-san-juan-blue-500 bg-summer-green-400 shadow border border-summer-green-500 rounded focus:outline-none hover:bg-summer-green-500">
              Try FREE now
            </button>
          </a>
          <a
            href="https://docs.latencylingo.com/docs/intro"
            target="_blank"
            rel="noreferrer"
          >
            <button className="ml-4 px-8 py-4 text-lg font-medium text-summer-green-700 bg-summer-green-100 shadow border border-summer-green-300 rounded focus:outline-none hover:bg-summer-green-200">
              Visit documentation
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
