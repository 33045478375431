import { Feature } from 'interfaces/home';
import { VscDebugDisconnect, VscRocket } from 'react-icons/vsc';
import { AiOutlineBarChart } from 'react-icons/ai';
import parser from 'html-react-parser';

interface Props {
  features: Feature[];
}

const getIcon = (i: number, size = 5) => {
  switch (i) {
    case 0:
      return <VscDebugDisconnect className={`h-${size} w-${size}`} />;
    case 1:
      return <VscRocket className={`h-${size} w-${size}`} />;
    case 2:
      return <AiOutlineBarChart className={`h-${size} w-${size}`} />;
  }
};

const Features1: React.FC<Props> = ({ features }) => {
  return (
    <div className="flex flex-wrap -mx-4 -mt-4 -mb-10 sm:-m-4">
      {features?.map((feature: Feature, i: number) => {
        return (
          <div
            className="flex flex-col items-center p-4 mb-6 text-center md:w-1/3 md:mb-0"
            key={`feature-${i}`}
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-20 h-20 mb-5 font-medium text-summer-green-700 bg-summer-green-100 rounded-full">
              {getIcon(i, 8)}
            </div>
            <div className="flex-grow">
              <h2 className="mb-3 text-lg font-medium text-san-juan-blue-500 title-font">
                {feature.name}
              </h2>
              <p className="text-base text-left leading-relaxed mx-auto w-5/6">
                {parser(feature.description)}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Features1;
